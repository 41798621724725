import zhCN from "antd-mobile/es/locales/zh-CN";
import trTR from "antd-mobile/es/locales/tr-TR";

export const appLocales = {
  "zh-CN": require("../locales/zh_CN.json"),
  "tr-TR": require("../locales/tr_TR.json"),
};

export const antdLocales = {
  "zh-CN": zhCN,
  "tr-TR": trTR,
};

export const languages = {
  // "zh-CN": "中文",
  "tr-TR": "Türkçe",
};
