// @ts-nocheck
import React, { useState, useEffect } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { ConfigProvider } from "antd-mobile";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import reduxThunk from "redux-thunk";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import intl from "react-intl-universal";
import { appLocales, antdLocales } from "@/config/locales";
import emit from "@/utils/emit";
import reducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const loadableProps = {
  timeout: 60 * 1000,
};
const Loading = () => null;

const Index = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Index" */ "@/pages/Index/Index"),
  loading: Loading,
});

const Box = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Box" */ "@/pages/Box/Box"),
  loading: Loading,
});

const Login = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Login" */ "@/pages/Login/Login"),
  loading: Loading,
});

// const BoxClassification = Loadable({
//   ...loadableProps,
//   loader: () =>
//     import(
//       /* webpackChunkName: "BoxClassification" */ "@/pages/Box/BoxClassification"
//     ),
//   loading: Loading,
// });

const Knapsack = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Knapsack" */ "@/pages/Knapsack/Knapsack"),
  loading: Loading,
});

const Cart = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Cart" */ "@/pages/Cart/Cart"),
  loading: Loading,
});

const Mine = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Mine" */ "@/pages/Mine/Mine"),
  loading: Loading,
});

const MineSetting = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineSetting" */ "@/pages/Mine/MineSetting"),
  loading: Loading,
});

const MineInvite = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineInvite" */ "@/pages/Mine/MineInvite"),
  loading: Loading,
});

const MineCoupon = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineCoupon" */ "@/pages/Mine/MineCoupon"),
  loading: Loading,
});

const MineConversion = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "MineConversion" */ "@/pages/Mine/MineConversion"
    ),
  loading: Loading,
});

const MineIncome = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineIncome" */ "@/pages/Mine/MineIncome"),
  loading: Loading,
});

const MineOrder = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineOrder" */ "@/pages/Mine/MineOrder"),
  loading: Loading,
});

const MineUnboxing = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineUnboxing" */ "@/pages/Mine/MineUnboxing"),
  loading: Loading,
});

const MineRecharge = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "MineRecharge" */ "@/pages/Mine/MineRecharge"),
  loading: Loading,
});

const MinePayPassword = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "MinePayPassword" */ "@/pages/Mine/MinePayPassword"
    ),
  loading: Loading,
});

const MineOrderDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "MineOrderDetail" */ "@/pages/Mine/MineOrderDetail"
    ),
  loading: Loading,
});

const SignIn = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "SignIn" */ "@/pages/SignIn/SignIn"),
  loading: Loading,
});

const SubmitOrder = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "SubmitOrder" */ "@/pages/SubmitOrder/SubmitOrder"
    ),
  loading: Loading,
});

const CartSubmitOrder = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "CartSubmitOrder" */ "@/pages/SubmitOrder/CartSubmitOrder"
    ),
  loading: Loading,
});

const AddressList = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "AddressList" */ "@/pages/Mine/AddressList"),
  loading: Loading,
});

const AddressEdit = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "AddressEdit" */ "@/pages/Mine/AddressEdit"),
  loading: Loading,
});

const BoxDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "BoxDetail" */ "@/pages/Box/BoxDetail"),
  loading: Loading,
});

const ProductDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "ProductDetail" */ "@/pages/Product/ProductDetail"
    ),
  loading: Loading,
});

const ProductClassification = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "ProductClassification" */ "@/pages/Product/ProductClassification"
    ),
  loading: Loading,
});

const PayCallBack = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "PayCallBack" */ "@/pages/PayCallBack/PayCallBack"
    ),
  loading: Loading,
});

const AppPayCallBack = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "AppPayCallBack" */ "@/pages/PayCallBack/AppPayCallBack"
    ),
  loading: Loading,
});

const Gifts = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Gifts" */ "@/pages/Knapsack/Gifts"),
  loading: Loading,
});

const Notice = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Notice" */ "@/pages/Notice/Notice"),
  loading: Loading,
});

const NoticeList = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "NoticeList" */ "@/pages/Notice/NoticeList"),
  loading: Loading,
});

const MineBoxConversion = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "MineBoxConversion" */ "@/pages/Mine/MineBoxConversion"
    ),
  loading: Loading,
});

const MineWithdrawal = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "MineWithdrawal" */ "@/pages/Mine/MineWithdrawal"
    ),
  loading: Loading,
});

function App() {
  const [antdLang, setAntdLang] = useState<any>(antdLocales["tr-TR"]);
  const [initLocaleDone, setInitLocaleDone] = useState<boolean>(false);

  const loadLocales = (lang = "tr-TR") => {
    intl
      .init({
        currentLocale: lang,
        locales: appLocales,
      })
      .then(() => {
        setAntdLang(antdLocales[lang]);
        localStorage.setItem("language", lang);
        setInitLocaleDone(true);
      });
  };

  useEffect(() => {
    emit.on("change_language", (lang: string) => loadLocales(lang));
    loadLocales(localStorage.getItem("language") || "tr-TR");
  }, []);

  return (
    initLocaleDone && (
      <ConfigProvider locale={antdLang}>
        <Router>
          <Provider store={store}>
            <Switch>
              <Route exact path="/" render={() => <Index />} />
              <Route exact path="/box" render={() => <Box />} />
              <Route exact path="/login" render={() => <Login />} />
              <Route exact path="/knapsack" render={() => <Knapsack />} />
              <Route exact path="/cart" render={() => <Cart />} />
              <Route exact path="/mine/:type?" render={() => <Mine />} />
              <Route
                exact
                path="/product/detail/:id/:type"
                render={() => <ProductDetail />}
              />
              <Route exact path="/sign-in" render={() => <SignIn />} />
              <Route
                exact
                path="/submit-order"
                render={() => <SubmitOrder />}
              />
              <Route
                exact
                path="/cart-submit-order/:ids"
                render={() => <CartSubmitOrder />}
              />
              <Route
                exact
                path="/address-list/:type?"
                render={() => <AddressList />}
              />
              <Route
                exact
                path="/address-edit/:id?"
                render={() => <AddressEdit />}
              />
              <Route
                exact
                path="/box/detail/:id"
                render={() => <BoxDetail />}
              />
              <Route
                exact
                path="/mine/page/setting"
                render={() => <MineSetting />}
              />
              <Route
                exact
                path="/mine/page/invite"
                render={() => <MineInvite />}
              />
              <Route
                exact
                path="/mine/page/coupon"
                render={() => <MineCoupon />}
              />
              <Route
                exact
                path="/mine/page/conversion"
                render={() => <MineConversion />}
              />
              <Route
                exact
                path="/knapsack/gifts/:ids"
                render={() => <Gifts />}
              />

              <Route
                exact
                path="/mine/page/income"
                render={() => <MineIncome />}
              />
              <Route
                exact
                path="/mine/page/order/:type?"
                render={() => <MineOrder />}
              />
              <Route
                exact
                path="/mine/page/order/detail/:id"
                render={() => <MineOrderDetail />}
              />
              <Route
                exact
                path="/mine/page/unboxing"
                render={() => <MineUnboxing />}
              />
              <Route
                exact
                path="/mine/page/recharge"
                render={() => <MineRecharge />}
              />
              <Route
                exact
                path="/mine/page/payPassword"
                render={() => <MinePayPassword />}
              />
              <Route exact path="/notice-list" render={() => <NoticeList />} />
              <Route exact path="/notice/:type/:id" render={() => <Notice />} />

              <Route
                exact
                path="/mine/page/box-conversion"
                render={() => <MineBoxConversion />}
              />
              <Route
                exact
                path="/mine/page/withdrawal"
                render={() => <MineWithdrawal />}
              />

              <Route
                exact
                path="/product/classification/:type/:id?"
                render={() => <ProductClassification />}
              />
              {/*<Route
                exact
                path="/box/classification/:type/:id?"
                render={() => <BoxClassification />}
              /> */}
              <Route
                exact
                path="/payCallBack/:id?"
                render={() => <PayCallBack />}
              />
              <Route
                exact
                path="/appPayCallBack/:id?"
                render={() => <AppPayCallBack />}
              />
            </Switch>
          </Provider>
        </Router>
      </ConfigProvider>
    )
  );
}

export default App;
